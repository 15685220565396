import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { memo, useState, useMemo } from 'react';
import ClerkBot from './ClerkBot';
import InfoBot from './InfoBot';
import StatsBot from './StatsBot';



const botList = [{
  'name': '申請派案機器人', 'component': ClerkBot
}, {
  'name': '詢問資訊機器人', 'component': InfoBot
}, {
  'name': '統計機器人', 'component': StatsBot
}]



function BotFrame () {
  //// Basics
  const [selBot, setSelBot] = useState(0);
  const BotComp = useMemo(()=>botList[selBot].component, [selBot])
  
  const handleSelectBot = (e) => setSelBot(e.target.value)
  
  
	return (<>
    <Box sx={{display: 'inline', float:'left'}}>
      <Select
        labelId="bot-select" id="bot-select" defaultValue={0} align='left'
        value={selBot} onChange={handleSelectBot} label="bot_type"
      >
        { botList.map( ({name}, idx) =>
          <MenuItem value={idx} key={idx}>{name}</MenuItem>
        ) }
      </Select>
    </Box>
    <BotComp />
  </>
  )
}

export default memo(BotFrame);