// React utils
import { memo, useState, useReducer, useRef, useEffect, useMemo } from 'react';
// API & Websocket utils
import axios from 'axios';
import { APIEndpoint } from '../App';
import useWebSocket from 'react-use-websocket';
// Material UI utils
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Stack, Typography, TextField, Button } from '@mui/material';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';



const initSetting = {
  center_r: -0.5, center_i: 0, radius:1, iterations: 1000, img_width: 1024, img_height: 768
}

const WSEndpoint = 'ws://localhost:5000/mandelbrot_drawer/ws'



function reduceSetting (state, action) {
  // console.log("first print", state, action);
  if (action.id in state) {
    return {...state, [action.id]: action.value}
  } else if (action.id === 'reset') {
    return initSetting
  } else { return state }
}



function MandelbrotSet () {
  //// Start Logic
  const [setting, opSetting] = useReducer(reduceSetting, initSetting);
  const [imgSetting, setImgSetting] = useState(null);
  // const [jobId, setJobId] = useState(null);
  const [mdbImg, setMdbImg] = useState(null);
  const [progress, setProgress] = useState(100);
  // const [precision, setPrecision] = useState(0);
  const [mousePos, setMousePos] = useState([0, 0]);
  const imgRef = useRef();
  const radRef = useRef();
  const start_job = () => {
    // Make progress 0
    setProgress(0);
    // TODO: Check value
    axios.post( APIEndpoint + '/mandelbrot_drawer/start', setting
    ).then( (resp) => {
      // setJobId(resp.data.data);
      console.log(resp.data);
      sendMessage(resp.data.data);
    }).catch( (err) => {
      console.log(err)
    })
    // console.log(setting);
  }
  
  //// Update (WebSocket) Logic
  const { sendMessage } = useWebSocket(WSEndpoint, {
    onOpen: () => console.log('opened'),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
    onMessage: (e) => {
      const {prog, img} = JSON.parse(e.data);
      console.log(prog);
      setMdbImg(img);
      setProgress(prog*100);
      if (prog === 1) setImgSetting(setting)
    }
  });
  
  //// Selecting points on Image
  // const precision = useMemo(()=>
    // imgSetting?.radius? 3-Math.log10(imgSetting.radius): 0,
  // [imgSetting])
  // useEffect(()=>{
    // if (imgSetting) setPrecision(3-Math.log10(imgSetting.radius));
  // }, [imgSetting])
  
  useEffect(()=>{
    const precision = imgSetting?.radius? 3-Math.log10(imgSetting.radius): 0;
    const mouseMoveHandler = (e) => {
      if (!imgSetting) return;
      const {left, top, height, width} = e.target.getBoundingClientRect()
      const unit = imgSetting.radius*2/( height<width? height: width );
      setMousePos([
        Number((imgSetting.center_r + (e.clientX-left-width/2) * unit).toFixed(precision)),
        Number((imgSetting.center_i - (e.clientY-top-height/2) * unit).toFixed(precision))
      ])
      // console.log({
          // p: precision,
          // r: imgSetting.radius,
          // x: Number((imgSetting.center_r + (e.clientX-left-width/2) * unit).toFixed(precision)),
          // y: Number((imgSetting.center_i + (e.clientY-top-height/2) * unit).toFixed(precision)),
          // w:e.target.innerWidth,
          // h:e.target.innerHeight
      // });
    }
    imgRef.current.addEventListener('mousemove', mouseMoveHandler);
    var dstruct_ref = imgRef.current;
    return(()=>{
      if (dstruct_ref) dstruct_ref.removeEventListener('mousemove', mouseMoveHandler);
    })
  }, [imgSetting])
  
  useEffect(()=>{
    if (progress === 100) imgRef.current.scrollIntoView(false, { behavior: "smooth", block: "center"});
  }, [progress])
  
  const setNewCenter = () => {
    radRef.current.scrollIntoView(false, { behavior: "smooth", block: "start"})
    opSetting({id:'center_r', value:mousePos[0]});
    opSetting({id:'center_i', value:mousePos[1]});
    // radRef.current.focus();
  }
  
  //// Reset setting
  const reset_setting = () => opSetting({id:'reset'})
  
  
  
  return (<Stack spacing={2}>
    <Paper>
      <Typography variant='body1' component={'span'}>Mandelbrot set centered at&nbsp;
        <Input
          label="Real" type="number" size="small" value={setting.center_r} name='center_r'
          onChange={(e)=>{opSetting({id:e.target.name, value:e.target.valueAsNumber})}}
        /> {setting.center_i<0? "-": "+"}&nbsp;j
        <Input
          label="Imaginary" type="number" size="small" value={Math.abs(setting.center_i)}
          name='center_i'
          onChange={(e)=>{opSetting({'id':e.target.name, 'value':e.target.valueAsNumber})}}
        /> in range of&nbsp;
        <Input ref={radRef}
          label="Radius" type="number" size="small" value={setting.radius} name='radius'
          onChange={(e)=>{opSetting({'id':e.target.name, 'value':e.target.valueAsNumber})}}
        />, iterate for&nbsp;
        <Input
          label="Iterations" type="number" size="small" sx={{width:'14ch'}}
          value = {setting.iterations} name='iterations'
          onChange={(e)=>{opSetting({'id':e.target.name, 'value':e.target.valueAsNumber})}}
        /> times on a canvas of (
        <Input
          label="Width" type="number" size="small" defaultValue="1024"
          sx={{width:'9ch'}} disabled
        />,&nbsp;
        <Input
          label="Height" type="number" size="small" defaultValue="768"
          sx={{width:'9ch'}} disabled
        />)
      </Typography>
      
      <Stack direction="row" alignItems="center">
        <Box sx={{ width: '100%', padding: '0px 5px'}}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={start_job} disabled={progress<100}>Start</Button>
          <Button disabled={true}>Abort</Button>
          <Button onClick={reset_setting}>Reset</Button>
        </Stack>
      </Stack>
    </Paper>
    <div style={{position: 'relative'}}>
      <Box component='img'
        ref={imgRef} src={mdbImg? `data:image/png;base64, ${mdbImg}`: ""}
        onClick={setNewCenter} sx={{backgroundSize: '100%'}}
      />
      <Box sx={{position: 'absolute', top:'0px', background: 'white'}}>
        { mousePos.length>0 && <Typography variant='body2'>
            Current position: {mousePos[0]}, {mousePos[1]}j&nbsp;
          </Typography>
        }
      </Box>
    </div>
  </Stack>)
}

export default memo(MandelbrotSet)