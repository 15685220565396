import { memo, useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';



function ChartJsNative ({ data }) {  
  const ctx = useRef(null);
  const componentChart = useRef(null);
  
  
  
  // Draw bar chart when chartData is made
  useEffect( () => {
    if (ctx.current && data) {
      if (componentChart.current) componentChart.current.destroy()
      componentChart.current = new Chart(ctx.current, {
        type: 'bar',
        data,
        options: {
          plugins: {
            legend: { display: false },
          },
          scales: {
            y: {
              title: { text: 'Number', display: true }
            },
            x: {
              title: { text: 'Char/Paragraph', display: true }
            }
          }
        }
      } )
    }
  }, [ctx, data] )
  
  
  
  return <><canvas ref={ctx}></canvas></>
}

export default memo(ChartJsNative);