import { Grid, Stack, Box, Paper } from '@mui/material';
import { memo, useState, useEffect } from 'react';
import DevicePanel from './DevicePanel';
import axios from 'axios';
import { APIEndpoint } from '../App';

function MultiTranscriber () {
  const [devices, setDevices] = useState([
    {device_name:'device_1', last_line: null, content: "", device_index:0}
  ]);  //device_name, 
  const [texts, setTexts] = useState([
    '小圓圓: 今天謝謝手譯及聽打的幫忙'
  ]);
  
  useEffect( () => {
    axios.get( APIEndpoint+"/multi_trcb/devices"
    ).then((resp)=>{
      console.log(resp.data);
      let retrieved_index = resp.data.data.map( (device_index)=>device_index );
      let old_index = [];
      setDevices( (cur) => [
        ...cur.filter( (device_index)=> {
          if (device_index in retrieved_index) {
            old_index.push(device_index)
            return true;
          } else {
            return false;
          }
        } ),
        ...resp.data.data.filter(
          (device_index) => !(device_index in old_index)
        ).map( (el)=>({...el, last_line: null, content: ""}) )
      ] );
      // console.log(old_index, retrieved_index)
    }).catch((err)=>{console.log(err)})
  }, [] )
  
  // useEffect(()=>{console.log(devices)}, [devices])
  
  return ( <Box sx={{height:'calc(100vh - 60px)'}}
    component={Grid} container columnSpacing={{xs:1}}>
    <Grid item lg={3} md={4} sm={6}>
      <Box sx={{background:'#cdffff', p:'5px', height:'100%'}}
        component={Stack} spacing={2}>
        { devices.map( ({device_name}, idx) =>
          <DevicePanel device_id={idx} key={idx} device_name={device_name} />
        ) }
      </Box>
    </Grid>
    <Grid item lg={9} md={8} sm={6}>
      <Box component={Stack} spacing={2}>
        { texts.map( (txt, idx) => <Paper key={idx}>{txt}</Paper> ) }
      </Box>
    </Grid>
  </Box> )
}

export default memo(MultiTranscriber)