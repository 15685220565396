import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography';

//// 4 Components for: Not login, Login Loading, Logged in but no permission, Logged in and verifying permission
// You are not logged in. Please login to access this page.
const NoAuthentication = () => {
    return (
        <Container style={{ textAlign: 'center', marginTop: '20%' }}>
            <Typography variant="h4">You are not logged in.</Typography>
            <Typography>Please login to access this page.</Typography>
        </Container>
    );
};

// Loading...
const Loading = () => {
    return (
        <Container style={{ textAlign: 'center', marginTop: '20%' }}>
            <Typography variant="h4">Loading...</Typography>
        </Container>
    );
};

// No permission to access this page. Please contact admin.
const NoPermission = () => {
    return (
        <Container style={{ textAlign: 'center', marginTop: '20%' }}>
            <Typography variant="h4">No permission to access this page.</Typography>
            <Typography>Please contact admin.</Typography>
        </Container>
    );
};

// Verifying permission...
const VerifyingPermission = () => {
    return (
        <Container style={{ textAlign: 'center', marginTop: '20%' }}>
            <Typography variant="h4">Verifying permission...</Typography>
        </Container>
    );
};



const Auth0Protected = ({ children, auth_func }) => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [isPermitted, setIsPermitted] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            setIsVerifying(true);
            getAccessTokenSilently().then(token => {
                if (auth_func === undefined) {
                    setIsPermitted(false);
                    setIsVerifying(false);
                    return;
                }
                auth_func(token, () => {
                    setIsPermitted(true);
                    setIsVerifying(false);
                }, () => {
                    setIsPermitted(false);
                    setIsVerifying(false);
                });
            }).catch(() => {
                setIsPermitted(false);
                setIsVerifying(false);
            });
        } else {
            setIsPermitted(false);
        }
    }, [isAuthenticated, getAccessTokenSilently, auth_func]);

    if (isLoading) {
        return <Loading />;
    } else if (!isAuthenticated) {
        return <NoAuthentication />;
    } else if (isVerifying) {
        return <VerifyingPermission />;
    } else if (!isPermitted) {
        return <NoPermission />;
    } else {
        return children;
    }
};

export default Auth0Protected;