import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import { Backdrop, Box, Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { memo, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { APIEndpoint } from '../App';
import OneTimer from '../components/TomatoTimer/OneTimer';
import NewTimer from '../components/TomatoTimer/NewTimer';
// Auth0 SDK
import { useAuth0 } from '@auth0/auth0-react';



  
function TomatoTimer () {
  // Vars about timing logic
  const [timers, setTimers] = useState([]);  //Timers
  const counter = useRef(null); // Hold the setInterval object  
  const wrkId = useRef(null); // The working timer
  const wrkMode = useRef(null); // The working timer's mode
  const targetTime = useRef(null); // Ending time for timer
  const baseTime = useRef(null); // Starting time of timer
  const lastUpdate = useRef(null); // Last time during countdown when calling API
  // Vars about init logic
  const { getAccessTokenSilently } = useAuth0();
  const [showInitOverlay, setInitOverlay] = useState(false);
  // Vars about overlay logic
  const [showEOTOverlay, setShowEOTOverlay] = useState(false);
  const [overlayText, setOverlayText] = useState("default");
  // Vars about delete logic
  const [showDelete, setShowDelete] = useState(false);
  const [deleteText, setDeleteText] = useState("default");
  const [isDeleting, setIsDeleting] = useState(false);
  const delId = useRef(null);
  // Vars about create logic
  const [isCreating, setIsCreating] = useState(false);
  // TODO: Background update to backend when counting (to avoid sudden closing)
  
  // Init timer by accessing API & Ask for permission
  const init_timer = async () => {
    // Get token
    const token = await getAccessTokenSilently();
    // Use token to get timer
    axios.post(APIEndpoint + "/tomato_timer/init_app", {}, {
      headers: {Authorization: `Bearer ${token}`}
    }).then((resp) => {
      setTimers(resp.data.data.map( timer => ({...timer, status: 'rest'}) ));
      setInitOverlay(false);
    }).catch((err)=>{console.log(err)});
  }
  useEffect( () => {
    // Init timer
    init_timer()
  }, [] )
  
  //// CLOCK LOGIC ////
  // Helper function: update one object
  const _update_one = (id, updated_timer, other_mode) => {
    let new_timers = timers.map( (timer) => 
        timer.id === id ? updated_timer : {...timer, status: other_mode?? timer.status}
    )
    setTimers( new_timers );
    // console.log(timers);
  }
  // Helper function: locate the timer
  const _cur_timer = (id) => timers.find(timer => timer.id === id);
  
  // Start timer
  const start_timer = (id, mode) => {
    
    // Check if another timer is working
    if (_is_counting()) {return false}
    
    // Set Id to bar others and set the mode
    wrkId.current = id; wrkMode.current = mode;
    
    // Set target time for ending
    switch (mode) {
      case 'meeting':
        targetTime.current = 0; break;
      case 'working':
        targetTime.current = Math.max(0, _cur_timer(id).tomato_time - 3000);
        break;
      case 'flower':
        targetTime.current = Math.max(
          0, 1500 * Math.floor((_cur_timer(id).flower_time-1) / 1500)
        ); break;
      default:
        console.log("Invalid mode: " + mode);
        return false;
    }
    
    //Start timer
    _update_one(id, {..._cur_timer(id), status: mode }, 'disabled')
    baseTime.current = Date.now();
    lastUpdate.current = Date.now();
    counter.current = setInterval(_count_down, 500);
  }
  
  // Stop timing
  const stop_timer = () => {
    // Stop timer
    clearInterval(counter.current);
    // Calculate necessary transition
    let ud_timer = _cur_timer(wrkId.current);
    while (ud_timer.transition_time >= 3000) {
      ud_timer.transition_time -= 3000;
      ud_timer.flower_time += 1500;
    }
    ud_timer.status = 'rest';
    // Update var
    _update_one(wrkId.current, ud_timer, 'rest');
    _reset();
    // Update to backend
    axios.post( APIEndpoint+"/tomato_timer/update", ud_timer
    ).then((resp)=>{}).catch((err)=>{console.log(err)})
  }
  
  // Modify time
  const modify_time = (id, mode, secs) => {
    let ud_timer = _cur_timer(id);
    
    //// Logic:
    switch (mode) {
      case 'tomato':
        // Tomato +: Must not exceed upper bound; Transition & flower time being subtracted;
        if (secs > 0) {
          if (secs > ud_timer.transition_time + 2*ud_timer.flower_time) {
            // TODO: Warning for not enough t+f time to substract (would exceed bound)
          } else {
            ud_timer.tomato_time += secs;
            ud_timer.transition_time -= secs;
            while (ud_timer.transition_time < 0) {
              ud_timer.transition_time += 3000;
              ud_timer.flower_time -= 1500;
            }
          }
          
        // Tomato -: Must not exceed 0; Transition & flower time being added;
        } else {
          let sub_secs = Math.min(-secs, ud_timer.tomato_time);
          ud_timer.tomato_time -= sub_secs;
          ud_timer.transition_time += sub_secs;
          while (ud_timer.transition_time >= 3000) {
            ud_timer.transition_time -= 3000;
            ud_timer.flower_time += 1500;
          }
        }
        break;
        
      case 'flower':
        // Flower +: No limit (DO LIMIT LATER)
        // Flower -: Must not exceed 0;
        ud_timer.flower_time = Math.max(0, ud_timer.flower_time + secs);
        break;
      default:
        console.log("Invalid mode: " + mode);
        return false;
    }
    // Update in frontend
    _update_one(id, ud_timer);
    // Update to backend
    axios.post( APIEndpoint+"/tomato_timer/update", ud_timer
    ).then((resp)=>{}).catch((err)=>{console.log(err)})
  }
  
  // Create timer
  const create_timer = (name) => {
    setIsCreating(true);
    // Create through API
    axios.post( APIEndpoint+"/tomato_timer/create", { name }
    ).then((resp)=>{
      // Update the array
      setTimers([...timers, {...resp.data.data[0], status: 'rest'}])
    }).catch((err)=>{console.log(err)}).finally(()=>{setIsCreating(false);})
  }
  
  // Delete timer
  const delete_timer = (id) => {
    setIsDeleting(true);
    // Delete through API
    axios.post( APIEndpoint+"/tomato_timer/delete", { id }
    ).then((resp)=>{
      // Remove that timer
      setTimers( timers.filter(timer => timer.id !== id) );
    }).catch((err)=>{console.log(err)}).finally(()=>{
      // Close dialog & Stop progress animation
      setShowDelete(false); setIsDeleting(false);
    })
  }
  
  // Receiving delete timer emits
  const pre_delete_timer = (id, tomatoDisplay) => {
    delId.current = id;
    setDeleteText(_cur_timer(id).name + " " + tomatoDisplay)
    setShowDelete(true);
  }
  
  // Helper function: Check if someone else is counting
  const _is_counting = () => (wrkId.current || wrkId.current===0)
  // Helper function: The count down
  const _count_down = () => {
    
    // This time lapse
    let deltasec = Math.round((Date.now()-baseTime.current)/1000)
    
    // Not enough time lapse for a change.
    if (!deltasec) {return null;}
    
    // Too much time lapse. Cancel the clock.
    // else if (deltasec > 60) {roundup("By >60s");}
    
    // Normal time lapse. Do the clock job.
    let ud_timer = _cur_timer(wrkId.current);
    // console.log(deltasec);
    
    switch (wrkMode.current) {
      case 'working':
      case 'meeting':
        // Passed seconds, not larger than existing tomato time, and not larger than target
        deltasec = Math.min(deltasec, ud_timer.tomato_time - targetTime.current)
        baseTime.current += deltasec*1000;
        ud_timer.tomato_time -= deltasec;
        ud_timer.transition_time += deltasec;
        if (ud_timer.tomato_time <= targetTime.current) {
          // Ending procedure
          _end_notification(baseTime.current);
          stop_timer();
          return null
        }
        break;
      case 'flower':
        deltasec = Math.min(deltasec, ud_timer.flower_time - targetTime.current)
        baseTime.current += deltasec*1000;
        ud_timer.flower_time -= deltasec;
        if (ud_timer.flower_time <= targetTime.current) {
          // Ending procedure
          _end_notification(baseTime.current);
          stop_timer();
          return null;
        }
        break;
      default:
        console.log("Invalid mode: " + wrkMode.current);
        return false;
    }
    // Frontend update
    ud_timer.status = wrkMode.current;
    _update_one(wrkId.current, ud_timer, 'disabled');
    
    // Check if backend needs update
    if ((Date.now()-lastUpdate.current) > 60000) {
      lastUpdate.current = Date.now();
      axios.post( APIEndpoint+"/tomato_timer/update", ud_timer
      ).then((resp)=>{}).catch((err)=>{console.log(err)})
    }
  }
  // Helper function: Notification for Ending
  const _end_notification = (end_unix) => {
    // Show the overlay
    let end_time = new Date(end_unix);
    let not_text = _cur_timer(wrkId.current).name + " " +
      wrkMode.current + " mode ends at " + 
      end_time.getHours().toString().padStart(2, '0') + ":" +
      end_time.getMinutes().toString().padStart(2, '0') + ":" + 
      end_time.getSeconds().toString().padStart(2, '0')
    // setOverlayText(not_text);
    // setShowEOTOverlay(true);
    
    // Send Notification
    alert(not_text);
    if (Notification.permission === 'granted') {
      var notificaiton = new Notification('Ding!', { body: "Timer's Up" });
    }
  }
  // Helper function: Reset counting variables
  const _reset = () => {
    wrkId.current = null; wrkMode.current = null;
    targetTime.current = null; baseTime.current = null;
    lastUpdate.current = null;
  }
  // Helper function: Notification on Title to blink
  useEffect(() => {
    let blinkInterval;

    if (showEOTOverlay) {
      blinkInterval = setInterval(() => {
        document.title = document.title === "React App" ? "⚠️ Timer's Up! ⚠️" : "React App";
      }, 1000); // Adjust the interval for faster or slower blinking
    }

    return () => {
      clearInterval(blinkInterval);
      document.title = "React App"; // Reset title when component unmounts
    };
  }, [showEOTOverlay]);
  
  return ( <>
    {/* OVERLAY FOR END OF TIMING */}
    <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={showEOTOverlay}>
      <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 1,
        boxShadow: 3, textAlign: 'center'
      }}>
        <Typography sx={{color:'common.black'}}>{overlayText}</Typography>
        <Button onClick={()=>setShowEOTOverlay(false)}>OK</Button>
      </Box>
    </Backdrop>
    
    {/* OVERLAY FOR READING INIT */}
    <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={showInitOverlay}>
      <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 1,
        boxShadow: 3, textAlign: 'center'
      }}>
        <Typography sx={{color:'common.black'}}>Initializing...</Typography>
        <CircularProgress size="20px"/>
      </Box>
    </Backdrop>
    
    {/*  DIALOG FOR DELETEION */}
    <Dialog open={showDelete} onClose={() => setShowDelete(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{deleteText}</DialogTitle>
      <DialogContent><DialogContentText id="alert-dialog-description">
        Are you sure you want to delete this clock?
      </DialogContentText></DialogContent>
      <DialogActions>
        <Button disabled={isDeleting} onClick={() => delete_timer(delId.current)}>
          {isDeleting? <CircularProgress size="20px"/>: "Delete"}
        </Button>
        <Button disabled={isDeleting} onClick={() => setShowDelete(false)} autoFocus>Cancel</Button>
      </DialogActions>
    </Dialog>
    
    {/* MAIN COMPONENTS LAYOUT */}
    <Stack direction="row" useFlexGap flexWrap="wrap" spacing={2}> {
      timers.map( (timer, idx) => <OneTimer
        timer={timer} key={idx}
        modify_time={modify_time}
        start_timer={start_timer}
        stop_timer={stop_timer}
        delete_timer={pre_delete_timer}
      />)
    } <NewTimer create_timer={create_timer} is_creating={isCreating}></NewTimer>
    </Stack>
  </> )
}

export default memo(TomatoTimer)