import axios from 'axios';
import { APIEndpoint } from '../App';

//// A set of API-calling functions for Tomato Timer function
// Auth check
export const authCheck = (token, callback_fn, error_fn) => {
    axios.get(`${APIEndpoint}/tomato_timer/auth_check`, {
        headers: { Authorization: `Bearer ${token}` }
    }).then((response) => {
        callback_fn(response.data.data);
    }).catch((error) => {
        error_fn(error);
    });
};