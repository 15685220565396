import { Grid, Box } from '@mui/material';
import { memo } from 'react';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';


function DialogTurn (props={speaker:'user', content:''}) {
  return ( <Grid container spacing={2}>
    {/* Robot's icon*/}
    <Grid item xs={1}>
      {props.speaker === 'bot' &&<SmartToyOutlinedIcon sx={{float:'right'}}/>}
    </Grid>
    
    {/* Spacing to push content to user */}
    {props.speaker === 'user' && <Grid item xs={2} />}
    
    {/* Main content */}
    <Grid item xs={8}>
      <Box sx={{background: '#84ff00', borderRadius: '10px', p: '10px', whiteSpace: 'pre-line'}}>{props.content}</Box>
    </Grid>
    
    {/* Spacing to push content to bot */}
    {props.speaker === 'bot' && <Grid item xs={2} />}
    
    {/* User's icon */}
    <Grid item xs={1}>
      {props.speaker === 'user' && <AccountCircleOutlinedIcon/>}
    </Grid>
  
  </Grid>)
}

export default memo(DialogTurn)