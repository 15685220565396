import { memo, useState, useEffect } from 'react';
import {Card, Grid, IconButton, Box, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import MicNoneIcon from '@mui/icons-material/MicNone';
import CircleIcon from '@mui/icons-material/Circle';

function DevicePanel (props) {
  const [isAvailable, setIsAvailable] = useState(false);
  const [isOn, setIsOn] = useState(true);
  
  // useEffect(()=>{console.log(props.device_name)}, [])
  
  return ( <Card sx={{p:'5px'}}>
    <Grid container columns={{xs:8}} columnSpacing={2}>
      <Grid item xs={4}><Stack direction='row'>
        <h4>Input:&nbsp;</h4>
        <h4 style={{
          textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'
        }}>{props.device_name}</h4>
      </Stack></Grid>
      <Grid item xs={4}><Stack direction='row' alignItems='center'>
        <h4>As:&nbsp;</h4>
        <TextField fullWidth defaultValue={"User " + props.device_id} size="small"/>
      </Stack></Grid>
      
      <Grid item xs={4}>
        <Box justifyContent="center" display="flex">
          { isAvailable?
            <KeyboardVoiceIcon sx={{fontSize:60}}/>:
            <MicNoneIcon sx={{fontSize:60}}/>
          }
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box justifyContent="center" display="flex"><IconButton>
          <CircleIcon sx={{color: (isOn? 'red': 'gray'), fontSize:40}}/>
        </IconButton></Box>
      </Grid>
      
    </Grid>
  </Card> )
}

export default memo(DevicePanel);