import { Typography } from '@mui/material';

function NoPage () {
  return (
    <Typography variant="h2" align='center'>
      <p>4🚫4</p>
      <p>ingat hkngyan qu abaw qani'</p>
      <p>Tshuē bô tsit ia̍h</p>
      <p>Page Not Found</p>
      <p>頁面不存在</p>
      <p>Seite nicht gefunden</p>
    </Typography>
  )
}

export default NoPage;