import { useState, useEffect, memo } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Typography } from '@mui/material';
import { listTasks, createTask, uploadCSV, listCSVFiles } from '../../api/table_lmi';

const TaskHistory = ({setTaskId, isSelectLocked}) => {
    const [open, setOpen] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [csvFiles, setCSVFiles] = useState([]);

    // Rest of the code...

    //// Initial fetch of tasks and CSV files
    useEffect(() => {
        listTasks(1, setTasks);
        listCSVFiles(1, setCSVFiles);
    }, []);

    const handleNewTask = () => {
        // If select is locked, do not open the dialog
        // TODO: Add a warning message when isSelectLocked is true
        if (isSelectLocked) { return; }
        setOpen(true);
    };

    const handleTaskClick = (task) => {
        // If select is locked, do not set task id
        // TODO: Add a warning message when isSelectLocked is true
        if (isSelectLocked) { return; }
        // Set task id to parent component
        setTaskId(task.id);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleSelectCSV = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    // Function to upload a new CSV file
    const handleUploadCSV = () => {
        // Upload a new CSV file
        uploadCSV(1, selectedFile, (csv_file_id) => {
            // Update the CSV file list
            listCSVFiles(1, setCSVFiles);
            // Create new task based on this file
            createTask(1, csv_file_id, "New Task", (task_id) => {
                // Call the API to get new task list
                listTasks(1, setTasks);
                // Set the task id to parent component
                setTaskId(task_id);
            });
        });
        // Close the dialog
        setOpen(false);
    };

    const handleChooseCSV = (csv_file) => {
        // Create new task based on this file
        createTask(1, csv_file.id, "New Task", (task_id) => {
            // Call the API to get new task list
            listTasks(1, setTasks);
            // Set the task id to parent component
            setTaskId(task_id);
        });
        // Close the dialog
        setOpen(false);
    };

    return (
        <Box>
            <Button variant="contained" color="primary" onClick={handleNewTask} style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                New Task
            </Button>

            <List style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                {tasks.map((task) => (
                    <ListItem button key={task.id} onClick={() => handleTaskClick(task)}>
                        <ListItemText primary={task.title} secondary={task.created_at} />
                    </ListItem>
                ))}
            </List>

            <Dialog open={open} onClose={handleDialogClose}>
                <DialogTitle>New Task</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">Upload a new CSV or choose from old CSV to start</Typography>
                    <TextField type="file" onChange={(e) => handleSelectCSV(e)} />
                    <Button variant="contained" color="primary" onClick={() => handleUploadCSV()}>
                        Upload CSV
                    </Button>
                    <List>
                        {csvFiles.map((csvFile) => (
                            <ListItem button key={csvFile.id} onClick={() => handleChooseCSV(csvFile)}>
                                <ListItemText primary={csvFile.filename} secondary={csvFile.created_at} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default memo(TaskHistory);