import {memo, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TaskHistory from '../components/TableLLMAgent/TaskHistory';
import ChatInterface from '../components/TableLLMAgent/ChatInterface';
import { useAuth0 } from '@auth0/auth0-react';
import NoPermissionOverlay from '../common/NoPermissionOverlay';
import { authCheck } from '../api/table_lmi';

const TableLLMAgent = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [task_id, setTaskId] = useState(-1);
    const [isSelectLocked, setIsSelectLocked] = useState(false);
    const [isPermitted, setIsPermitted] = useState(false);

    // Check if the user has permission to access this page
    useState( () => {
        const checkPermission = async () => {
            const token = await getAccessTokenSilently();
            authCheck(token, () => {setIsPermitted(true);});
        };
        checkPermission();
    }, [getAccessTokenSilently]);
    
    return (<>
        <NoPermissionOverlay open={!isPermitted} />
        <Box sx={{ display: 'flex', height: 'calc(100vh - 68px)'}}>
            {/* Sidebar */}
            <Box sx={{ width: '20%', bgcolor: '#f5f5f5', padding: '1rem', overflowY: 'auto' }}>
                <Box>
                    <TaskHistory setTaskId={setTaskId} isSelectLocked={isSelectLocked}/>
                </Box>
            </Box>

            {/* Main Area */}
            <Box sx={{ width: '80%', padding: '1rem', overflowY: 'auto' }}>
                <Typography variant="h6">Chat Interface</Typography>
                <Box sx={{ maxHeight: '94%', overflowY: 'auto' }}>
                    <ChatInterface task_id={task_id} setIsSelectLocked={setIsSelectLocked} style={{ height: '100%' }} />
                </Box>
            </Box>
        </Box>
    </>);
};

export default memo(TableLLMAgent);