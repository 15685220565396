import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { memo, useEffect, useState } from 'react';



function OneTimer ({timer, modify_time, start_timer, stop_timer, delete_timer}) {
  // Compute the clock display
  const [tomatoDisplay, setTomatoDisplay] = useState("");
  const [tomatoResidual, setTomatoResidual] = useState("");
  const [flowerResidual, setFlowerResidual] = useState("");
  const [modTime, setModTime] = useState(0);
  
  // Display Tomatos and Flowers (and Star)
  useEffect(()=>{
    setTomatoDisplay(compute_tomato_display(timer));
    // console.log("triggered", tomatoDisplay);
  }, [timer.tomato_time, timer.flower_time]);
  
  const compute_tomato_display = ({tomato_time, flower_time, transition_time}) => 
    "🍅".repeat(Math.ceil(tomato_time / 3000)) + 
    "🌻".repeat(Math.ceil(flower_time / 1500)) +
    (tomato_time + flower_time + transition_time === 0? "⭐": "")
    
  // Display Time
  useEffect(()=>{
    setTomatoResidual(sec2minsec(timer.tomato_time%3000));
    setFlowerResidual(sec2minsec(timer.flower_time%1500));
  }, [timer.tomato_time, timer.flower_time]);
  
  const sec2minsec = (secs) => {
    return Math.floor((secs)/60).toString().padStart(2, '0') + ":" +
      Math.round((secs)%60).toString().padStart(2, '0')
  }
  
  
  
  //// Handler
  const handle_modify_time = (mode, sign) => {
    if (modTime===0) {return false}
    modify_time(timer.id, mode, modTime*60*(sign==='-'? -1: 1));
    setModTime(0);
  }
  
  const handle_start_stop = (mode) => {
    // Everything is at rest. Just go.
    if (timer.status === 'rest') {start_timer(timer.id, mode)}
    // Clicking on the same button means to stop.
    else if (timer.status === mode) {stop_timer(timer.id)}
  }
  
  
  
  //<Typography>{tomatoDisplay}</Typography>
  return ( <Card>
    <Typography>{timer.name}</Typography>
    <Typography>{tomatoDisplay}</Typography>
    <Grid container>
      <Grid item xs={3}>
        <Typography>Tomato</Typography>
        <Typography>{tomatoResidual}</Typography>
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={3}>
        <Typography>Flower</Typography>
        <Typography>{flowerResidual}</Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={3}>
        <Button size="small" onClick={()=>handle_modify_time('tomato', '+')}>➕</Button>
        <Button size="small" onClick={()=>handle_modify_time('tomato', '-')}>➖</Button>
      </Grid>
      <Grid item xs={6}>
        <TextField
          size="small" sx={{width: "100px"}}
          label="Time in secs" type="number" min={0}
          value={modTime} onChange={(event) => {
            setModTime(event.target.value);
          }}>
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <Button size="small" onClick={()=>handle_modify_time('flower', '+')}>➕</Button>
        <Button size="small" onClick={()=>handle_modify_time('flower', '-')}>➖</Button>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={6}><Button sx={{width: '100%'}}
        disabled={!['rest', 'meeting'].includes(timer.status)}
        variant={timer.status==='meeting'? "contained": "outlined"}
        onClick={() => handle_start_stop('meeting')}
      >{timer.status==='meeting'? "Stop": "Meeting Mode"}</Button></Grid>
      <Grid item xs={6}><Button sx={{width: '100%'}}
        disabled={!['rest', 'working'].includes(timer.status)}
        variant={timer.status==='working'? "contained": "outlined"}
        onClick={() => handle_start_stop('working')}
      >{timer.status==='working'? "Stop": "Working Mode"}</Button></Grid>
    </Grid>
    <Grid container>
      <Grid item xs={6}><Button sx={{width: '100%'}}
        disabled={!['rest', 'flower'].includes(timer.status)}
        variant={timer.status==='flower'? "contained": "outlined"}
        onClick={() => handle_start_stop('flower')}
      >{timer.status==='working'? "Stop": "Flower Mode"}</Button></Grid>
      <Grid item xs={6}><Button sx={{width: '100%'}}
        disabled={timer.status!=='rest'}
        variant="outlined"
        onClick={() => delete_timer(timer.id, tomatoDisplay)}
      >Delete</Button></Grid>
    </Grid>
  </Card> )
}

export default memo(OneTimer)