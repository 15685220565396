import { useEffect, useState, memo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { APIEndpoint } from '../App';

const TestSecurity = () => {
    const { getAccessTokenSilently, isAuthenticated, isLoading, user } = useAuth0();
    const [protectedData, setProtectedData] = useState(null);

    // Show isAuthenicated when the page is loaded or when this value changes
    useEffect(()=>{
        console.log(`isAuthenticated: ${isAuthenticated}`);
        console.log(`isLoading: ${isLoading}`);
        console.log(user);
        // const token = getAccessTokenSilently();
        // console.log(`token: ${token}`);
    }, [isAuthenticated, isLoading, user]);

    useEffect(() => {
        const fetchProtectedData = async () => {
            try {
                const token = await getAccessTokenSilently();
                const response = await axios.get(APIEndpoint+"/test_security", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setProtectedData(response.data);
                console.log(response.data.data);
            } catch (error) {
                console.error('Error fetching protected data', error);
            }
        };
        isAuthenticated && fetchProtectedData();
    }, [isAuthenticated]);

    if (isLoading) {
        return <div>Loading...</div>;
    } else if (!isAuthenticated) {
        return <div>Not authenticated</div>;
    } else if (!isLoading && isAuthenticated) {
        return ( <div>
            <h1>Protected Page</h1>
            {protectedData ? ( <>
                <pre>{JSON.stringify(protectedData, null, 2)}</pre>
                <pre>{JSON.stringify(user , null, 2)}</pre>
                </>) : (
                <p>Loading protected data...</p>
            )}
        </div> );
    };
}

export default memo(TestSecurity);

// export default withAuthenticationRequired(TestSecurity, {
//     onRedirecting: () => <div>Loading...</div>,
// });