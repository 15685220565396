import { memo, useState, useEffect } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { getChatHistory, sendMessage, startTask } from '../../api/table_lmi';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import remarkDirective from 'remark-directive';
import { visit } from 'unist-util-visit';

const ChatInterface = ({task_id, setIsSelectLocked}) => {
    const [chatTurns, setChatTurns] = useState([]);
    const [message, setMessage] = useState("");
    const [isSendingMsg, setIsSendingMsg] = useState(false);

    // Load task's past chat turns when task_id changes or specified
    useEffect(() => {
        // Clear input message when task_id changes
        setMessage("");
        // If task_id is valid, get the chat history
        if (task_id > -1) {getChatHistory(task_id, (chat_turns) => {
            // If chat_turns is empty, start a task by getting welcome message.
            if (chat_turns.length === 0) {
                startTask(task_id, (welcome_message) => {
                    console.log(welcome_message);
                    setChatTurns(welcome_message);
            } ) } else {
                setChatTurns(chat_turns);
    } });} }, [task_id]);

    // Send a message to the task
    const handleSendClick = () => {
        // If message is empty, do not send
        if (!message) { return; }
        // Send the message
        setIsSelectLocked(true);
        setIsSendingMsg(true);
        const userMessage = {role: 'user', content: message}; // Store the message
        // Push message to chatTurns and then clear the message
        setChatTurns([...chatTurns, userMessage]);
        sendMessage(task_id, message, (response) => {
            // Update the chat turns
            setChatTurns((prevChatTurns) => [...prevChatTurns, response]);
        }, () => {
            // Unlock and clear the message whether the API call is successful or not
            setIsSelectLocked(false);
            setIsSendingMsg(false);
            setMessage("");
        });
    }

    // Handle text input change
    const handleInputChanged = (e) => {
        setMessage(e.target.value);
    }


    function directivePlugin() {
        return (tree) => {
          visit(tree, (node) => {
            if (
              (node.type === 'textDirective' || node.type === 'leafDirective' || node.type === 'containerDirective') &&
              node.name === 'spoiler'
            ) {
              const data = node.data || (node.data = {});
              data.hName = 'Spoiler';
              data.hProperties = { title: node.attributes.title };
            }
          });
        };
    }

    const Spoiler = ({ title, children }) => (
    <details>
        <summary>{title || 'Spoiler'}</summary>
        {children}
    </details>
    );

    return (
        <Box sx={{display: "flex", flexDirection: "column"}}>
            {/* Chat boxes */}
            <Box sx={{flexGrow: 1, overflowY: 'hidden', padding: '1rem'}}>
                 {chatTurns.map((turn, index) => (
                <Box key={index} sx={{ display: 'flex', justifyContent: turn.role === 'user' ? 'flex-end' : 'flex-start' }}>
                    {/* User chat box */}
                    {turn.role === 'user' && (
                        <Box sx={{ backgroundColor: '#e0e0e0', padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
                            <Markdown>{turn.content}</Markdown>
                        </Box>
                    )}

                    {/* Bot/Assistant chat box */}
                    {turn.role === 'assistant' && (
                        <Box sx={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
                            <Markdown components={{ Spoiler }} remarkPlugins={[remarkGfm, remarkDirective, directivePlugin]}>{turn.content}</Markdown>
                        </Box>
                    )}
                </Box>
            ))} </Box>

            {/* Text input */}
            <Box sx={{
                position: 'sticky', // Stick to the bottom of the viewport
                bottom: 0, // Stay at the bottom
                borderTop: '1px solid #ddd', // Optional: separator between messages and input
                padding: '1rem',
                bgcolor: 'white', // Ensure the background color is set
            }}>
                <TextField label={
                    task_id > -1 ? "Type your message" : 'Press "New Task" to start a new task or Select past task to continue'
                } fullWidth multiline
                    disabled={!(task_id > -1) || isSendingMsg}
                    value={message} onChange={handleInputChanged}
                />
                <Button variant="contained" color="primary"
                    disabled={!(task_id > -1) || isSendingMsg} onClick={handleSendClick}>Send
                </Button>
            </Box>
        </Box>
    );
};

export default memo(ChatInterface);