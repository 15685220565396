import axios from 'axios';
import { APIEndpoint } from '../App';

//// A set of API-calling functions for Table LMI function
// Checking for user's permission to access the page
export const authCheck = (token, callback_fn, error_fn) => {
    axios.get(`${APIEndpoint}/table_lmi/auth_check`, {
        headers: { Authorization: `Bearer ${token}` }
    }).then((response) => {
        callback_fn(response.data.data);
    }).catch((error) => {
        error_fn(error);
    });
};

// Get task list of a user with user id
export const listTasks = (user_id, callback_fn) => {
    axios.get(`${APIEndpoint}/table_lmi/list_tasks?user_id=${user_id}`)
        .then((response) => {
            callback_fn(response.data.data.tasks);
        }).catch((error) => {
            console.error(error);
        });
};

// Upload a new CSV file with user id and file
export const uploadCSV = (user_id, file, callback_fn) => {
    let formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('file', file);
    axios.post(`${APIEndpoint}/table_lmi/upload_csv`, formData)
        .then((response) => {
            callback_fn(response.data.data.csv_file_id);
        }).catch((error) => {
            console.error(error);
        });
};

// Create a new task with user id, csv file id, and title
export const createTask = (user_id, file_id, title, callback_fn) => {
    axios.post(`${APIEndpoint}/table_lmi/create_task`, {
        user_id: user_id,
        csv_file_id: file_id,
        title: title
    }).then((response) => {
        callback_fn(response.data.data.task_id);
    }).catch((error) => {
        console.error(error);
    });
};

// List uploaded CSV files of a user with user id for selection
export const listCSVFiles = (user_id, callback_fn) => {
    axios.get(`${APIEndpoint}/table_lmi/list_csv_files?user_id=${user_id}`)
        .then((response) => {
            callback_fn(response.data.data.csv_files);
        }).catch((error) => {
            console.error(error);
        });
};

// Retrieve chat history of a task with task id
export const getChatHistory = (task_id, callback_fn) => {
    axios.get(`${APIEndpoint}/table_lmi/task_chat?task_id=${task_id}`)
        .then((response) => {
            callback_fn(response.data.data.chat_history);
        }).catch((error) => {
            console.error(error);
        });
};

// Send a message to a task with task id and message
export const sendMessage = (task_id, message, callback_fn, finally_fn) => {
    axios.post(`${APIEndpoint}/table_lmi/send_message`, {
        role: "user",
        content: message,
        task_id: task_id
    }).then((response) => {
        callback_fn(response.data.data[0]);
    }).catch((error) => {
        console.error(error);
    }).finally(() => {
        if (finally_fn) { finally_fn(); }
    });
};

// Start the task (get the welcome message) with task id
export const startTask = (task_id, callback_fn) => {
    axios.post(`${APIEndpoint}/table_lmi/start_task?task_id=${task_id}`)
        .then((response) => {
            callback_fn(response.data.data);
        }).catch((error) => {
            console.error(error);
        });
};

