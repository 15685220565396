import * as d3 from "d3";
import { memo, useEffect, useRef, useId, useState } from 'react';



function D3Native ({ data }) {
  const ctx = useRef(null);
  const container = useRef(null);
  // const [svgHeight, setSvgHeight] = useState(0)
  // const ctx_id = useId();
  
  useEffect(()=>{ if (ctx.current && data) draw(ctx, data)}, [ctx, data])
  
return <><svg ref={ctx} width='100%' style={{aspectRatio: 2}}/></>
}

export default memo(D3Native)



function draw(ctx, data) {
  // TODO: Make it responsive to data change
  
  // console.log(data);
  // Declare the chart dimensions and margins.
  const width = ctx.current.clientWidth;
  const height = ctx.current.clientHeight;
  const marginTop = 30;
  const marginRight = 0;
  const marginBottom = 30;
  const marginLeft = 40;
  
  // Declare the x (horizontal position) scale.
  const x = d3.scaleBand()
    .domain(data.map(({interval})=>interval))
    .range([marginLeft, width - marginRight])
    .padding(0.1);
  
  // Declare the y (vertical position) scale.
  const y = d3.scaleLinear()
    .domain([0, d3.max(data.map( ({counts})=>counts ))])
    .range([height - marginBottom, marginTop]);
  // console.log(x, y)
  
  // Create the SVG container.
  const svg = d3.select(ctx.current)
    // .attr("width", width)
    // .attr("height", height)
    .attr("viewBox", [0, 0, width, height]);
    // .attr("style", "max-width: 100%; height: auto;");
  
  
  
  // Add a rect for each bar.
  ( svg.select('g.main').node()? svg.select('g.main'): svg.append("g").attr("class", 'main') )
    .attr("fill", "steelblue")
    .selectAll("rect")
    .data(data)
    .join("rect")
    .attr("x", (d) => x(d.interval))
    .attr("y", (d) => y(d.counts))
    .attr("height", (d) => y(0) - y(d.counts))
    .attr("width", x.bandwidth());
  
  // Add the x-axis and label.
  ( svg.select('g.xAxis').node()? svg.select('g.xAxis'): svg.append("g").attr("class", "xAxis") )
    .attr("transform", `translate(0,${height - marginBottom})`)
    .call(d3.axisBottom(x).tickSizeOuter(0));
  
  // Add the y-axis and label, and remove the domain line.
  ( svg.select('g.yAxis').node()? svg.select('g.yAxis'): svg.append("g").attr("class", "yAxis") )
      .attr("transform", `translate(${marginLeft},0)`)
      .call(d3.axisLeft(y).tickFormat((y) => (y).toFixed()))
      .call(g => g.select(".domain").remove())
      .call(g => g.append("text")
          .attr("x", -marginLeft)
          .attr("y", 10)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .text("↑ Frequency (%)"));
  /*
  // Return the SVG element.
  return svg.node();
  
  
  console.log(data)
  // Preprocessing data
  let maxY = Math.max(...data.datasets[0].data);
  
  
  // Define basic element
  let svg = d3.select(ctx.current);
  svg.append("a")
    .attr("href", "http://www.google.com")
    .append("circle")
    .attr("cx","50px")
    .attr("cy","50px")
    .attr("r","10px")
    .attr("fill","red");
    
  
  
  // Y axis
  let yg = svg.select("g.yAxis")
  if ( yg.empty() ) { yg = svg.append("g").attr("class", "yAxis") }
  yg.attr("transform", "translate(40, 20)")
    .call( d3.axisLeft(
      d3.scaleLinear()
        .domain([0, svg.clientHeight])
        .rangeRound([430, 0])
    ) )
    
  // X axis
  let xg = svg.select("g.xAxis")
  if ( xg.empty() ) { xg = svg.append("g").attr("class", "xAxis") }
  xg.attr("transform", "translate(40, 450)")
    .call( d3.axisBottom(
      d3.scaleBand()
        .domain( data.labels )
        .rangeRound([0, data.labels*35])
        // text of scaleBand target at the center of each range
        // so for range itself, just set it to the full length of the axis
    ) )
    .selectAll("text")
    .attr("transform", "rotate(90) translate(7, -12)")
    .style("text-anchor", "start");
  svg.attr("viewbox", "0, 0, "+data.labels*35+100+", 500");
  */
}

// function dynamic_height (ctx, data) {
  // ctx.currentTarget.
// }