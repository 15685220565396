import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { memo, useEffect, useState } from 'react';



function NewTimer ({create_timer, is_creating}) {
  const [newName, setNewName] = useState("");
  
  useEffect( () => {
    let now = new Date();
    setNewName( now.getFullYear() + "-" +
      (now.getMonth()+1).toString().padStart(2, '0')
      + "-" + now.getDate().toString().padStart(2, '0')
    );
  }, [])
  
  
  
  //<Typography>{tomatoDisplay}</Typography>
  return ( <Card><Stack>
    <TextField
      label="New timer's name" value={newName}
      onChange={(event) => setNewName(event.target.value)}
    ></TextField>
    <Button onClick={()=>{create_timer(newName)}} disabled={is_creating}>
      {is_creating? <CircularProgress size="20px"/>: "Create"}
    </Button>
  </Stack></Card> )
}

export default memo(NewTimer)