import { memo } from 'react';
import { Bar } from 'react-chartjs-2';



const options = {
  plugins: { legend: { display: false } },
  scales: {
    y: { title: { text: 'Number', display: true } },
    x: { title: { text: 'Char/Paragraph', display: true } }
  }
};



function ReactChartJs2 ({ data }) {  
  return <><Bar options={options} data={data} /></>
}

export default memo(ReactChartJs2);