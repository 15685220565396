import { Outlet, Link } from 'react-router-dom';
import { useState, memo } from 'react';
import { Toolbar, Button, Menu, MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { useAuth0 } from "@auth0/auth0-react";
import config from '../config';

function Layout () {
  // Auth0 Login
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  // Styling: Bar Button
  const sxBarButton = {
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
    textTransform: 'capitalize'
  }
  
  // Styling: Menu Button
  
  // Menu logic
  const [anchorEl, setAnchorEl] = useState(null);
  const menuIsOpen = Boolean(anchorEl);
  const openProjMenu = (e) => {
      setAnchorEl(e.currentTarget);
  }
  const closeProjMenu = () => {
    setAnchorEl(null);
  }
  
  // Menu data
  const menuData = [
    {uri: 'multi_transcriber', caption: 'Multi-Input Transcriber'},
    {uri: 'd3_collection', caption: 'D3 Collection'},
    {uri: 'mandelbrot_set', caption: 'Mandelbrot Set'},
  ].concat(isAuthenticated ? [
    {uri: 'llm_bot', caption: 'LLM-powered Bots'},
    {uri: 'tomato_timer', caption: 'Tomato Timer'},
    {uri: 'table_llm_agent', caption: 'Table LLM Agent'},
  ] : [] );

  // Test code: See menuData when loaded
  useState(()=>{
    console.log(menuData);
  }, [isAuthenticated]);
  
  return ( <>
    <Menu anchorEl={anchorEl} open={menuIsOpen} onClose={closeProjMenu}>
      { menuData.map(({uri, caption}, idx)=>
        <MenuItem component={Link} to={'/proj/'+uri} key={idx} onClick={closeProjMenu}>
          {caption}
        </MenuItem>
      ) }
    </Menu>
    <AppBar>
      <Toolbar variant='dense' sx={{background:'#cdffcd', justifyContent: 'space-between'}}>
        <div>
          <Button sx={sxBarButton} variant="text" component={Link} to='/'>About</Button>
          <Button sx={sxBarButton} variant="text" onClick={openProjMenu}>Projects</Button>
          {/* <Button sx={sxBarButton} variant="text" component={Link} to='/acuti_wiki'>ACUTI Wiki</Button> */}
          {/* <Button sx={sxBarButton} variant="text" component={Link} to='/test_scrt'>Test Security</Button> */}
        </div>
        {isAuthenticated?
          <Button sx={sxBarButton} variant="text" onClick={() => logout( {logoutParams: {returnTo: config.AUTH0_CALLBACK} } )}>Logout</Button>:
          <Button sx={sxBarButton} variant="text" onClick={() => loginWithRedirect()}>Login</Button>
        }
      </Toolbar>
    </AppBar>
    <div style={{marginTop: 60}}>
      <Outlet />
    </div>
  </> )
}

export default memo(Layout);