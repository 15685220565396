import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';

const NoPermissionOverlay = ({ open }) => {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: 5 }} open={open}>
            <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 1, boxShadow: 3, textAlign: 'center' }}>
                <WarningIcon sx={{ fontSize: 40, color: 'error.main' }} />
                <Typography sx={{ color: 'common.black', mt: 2 }}>No Access Permission</Typography>
            </Box>
        </Backdrop>
    );
};

export default NoPermissionOverlay;