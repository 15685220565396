


export function ChartJSDataMaker (raw_data) {
  return {
    labels: raw_data.map( ({interval}) => interval ),
    datasets: [{
      data: raw_data.map( ({counts}) => counts ),
      backgroundColor: ["rgb(70, 130, 180)"],
      borderColor: ["rgb(60, 120, 170)"],
      borderWidth: 1
    }]
  }
}