import Container from '@mui/material/Container';
import { Stack, TextField, Grid } from '@mui/material';
import DialogTurn from './DialogTurn';
import { memo, useState, useEffect, useRef } from 'react';
// import { EndpointContext } from '../App';
import { APIEndpoint } from '../App';
import axios from 'axios';


function ClerkBot () {
  //// Basics
  // const endpoint = useContext(EndpointContext);
  const [dialog, setDialog] = useState([]);
  // const [inputBuffer, setInputBuffer] = useState("");
  const inputArea = useRef();
  const inputComp = useRef();
  
  //// Sending message logic
  const send_message = (e) => {
    // Not Enter. Don't send.
    if (e.key !== 'Enter') { return null }
    
    // No Input. Don't send.
    e.preventDefault();
    if (!e.target.value) { return null }
    
    // Update user's words onto UI
    e.target.disabled = true;
    setDialog( (cur) => [...cur, {'role': 'user', 'content': e.target.value}] );
    // setTurnCount((cur)=>cur+1);
  }
  
  useEffect( () => {
    // Send user's words to backend when it's user's trigger
    inputArea.current.value = "";
    // if (!inputBuffer) {return ()=>{};}
    if (!dialog.length || dialog[dialog.length-1].role==='bot') {return ()=>{};}
    console.log(APIEndpoint, process.env.REACT_APP_BACKEND_ENDPOINT)
    axios.post( APIEndpoint+"/clerk_bot/app_bot/talk", { dialog }
    
    // Update bot's response onto the UI
    ).then( (resp) => {
      // TODO: Error handling for wrong API code etc.
      // console.log(inputArea.current);
      setDialog( (cur) => [...cur, {'role': 'bot', 'content': resp.data.data}] );
      // console.log(dialog);
      if (resp.data.status!==1) {
        inputArea.current.disabled = false;
        inputArea.current.focus()
      }
      
    // Error handling    
    }).catch( (error) => {
      console.log(error);
      
    // Re-open dialog entry  
    }).finally( () => {
    });
  }, [dialog] );
  
  // Scroll to bottom
  useEffect(()=>{
    // console.log(inputComp.current);
    inputComp.current.scrollIntoView({ behavior: "smooth", block: "end"});
  }, [dialog.length])
  
  
  
	return (
		<Container maxWidth="md">
      <Stack spacing={2}>
        { [{ 'role': 'bot', 'content': '歡迎利用本機器人申請手譯或聽打服務'}, ...dialog].map(
          ({role, content}, idx) =>
          <DialogTurn key={idx} speaker={role} content={content}></DialogTurn> 
        ) }
        <Grid container spacing={2}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <TextField fullWidth autoFocus multiline ref={inputComp}
              onKeyPress={send_message} inputRef={inputArea}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Stack>
    </Container>
	)
}

export default memo(ClerkBot);