import { memo } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import BarChart from './BarChart';



function D3Collection () {
  return ( <Grid container>
  
    <Grid item xs={12} md={6} xl={4}><Box component={Paper} sx={{m:'5px', p:'5px'}}>
      <BarChart />
    </Box></Grid>
    
    <Grid item xs={12} md={6} xl={4}><Box component={Paper} sx={{m:'5px', p:'5px'}}>
      
    </Box></Grid>
    
  </Grid> )
}

export default memo(D3Collection);